/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, ColumnCover, ColumnDiv, Text, Image, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-13nnxen css-1hjv1em --full pb--30 pt--50" name={"uvod"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/8f28a11df74f43edac16841a7804d1e5_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/8f28a11df74f43edac16841a7804d1e5_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/8f28a11df74f43edac16841a7804d1e5_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/8f28a11df74f43edac16841a7804d1e5_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/8f28a11df74f43edac16841a7804d1e5_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/8f28a11df74f43edac16841a7804d1e5_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/8f28a11df74f43edac16841a7804d1e5_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/8f28a11df74f43edac16841a7804d1e5_s=3000x_.jpg);
    }
  
background-position: 85% 51%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"paddingTop":0}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white\"><br><br><br><br>Kseniia &amp; Dmytro<br><br>Wedding day<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--80" name={"informace"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--1 fs--30 w--300 ls--004 lh--12" style={{"maxWidth":880}} content={"<span style=\"color: var(--black);\">Ми не уявляємо це свято без вас – близьких та дорогих нам людей!&nbsp;<br>І будемо щасливі розділити з вами радість весільної урочистості!<br><br><br><br>&nbsp;| 7.09.2023 | 16:30 | <br>| Soho Restaurant |<br>&nbsp;| Podolské nábřeží 1, Praha 4|<br><br></span><br><span style=\"font-style: italic; color: var(--color-blend--50);\">Для нас важливо аби свято пройшло в камерній атмосфері , тому велике прохання не висвітлювати нашу подію в соціальних мережах. <br>Дякуємо за розуміння!</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-lml2hm css-pkgful" name={"informace-2"} style={{"marginBottom":0,"paddingBottom":632}} layout={"l30"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/32588/7428c67e0106441a8c3e346f6999f576_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/32588/7428c67e0106441a8c3e346f6999f576_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/32588/7428c67e0106441a8c3e346f6999f576_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/32588/7428c67e0106441a8c3e346f6999f576_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/32588/7428c67e0106441a8c3e346f6999f576_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/32588/7428c67e0106441a8c3e346f6999f576_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/32588/7428c67e0106441a8c3e346f6999f576_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/32588/7428c67e0106441a8c3e346f6999f576_s=3000x_.jpg);
    }
  
background-position: 68% 49%;
    `}>
          
          <ColumnWrap className="column__flex --center el--2 mt--0 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pt--80" name={"program"} style={{"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62" style={{"maxWidth":880}} content={"<span style=\"color: var(--black);\">Plan of the day</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"program-2"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 mt--0 pt--0 flex--center" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--left" name={"program-3"} style={{"paddingTop":0,"paddingBottom":49,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1300}} columns={"1"}>
            
            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--black);\">16:30</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Welcome Prosecco &amp; Canapes<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/17edc39d593b4f768700b843c341b22d.svg"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--black);\">17:00</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Wedding ceremony<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/3cc5b5a96ef04323b543f2b8b4a55fe3.svg"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--black);\">17:30</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Cocktail hour &amp; Photos<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/51712b7212824833b658ef7cad5f50b9.svg"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--black);\">18:30</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Wedding dinner<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/29288f33de65415482aafafd30dca7c3.svg"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--black);\">20:30</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Newlyweds first dance<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/25976468ae274352aeecbf97e79d5cd5.svg"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--black);\">21:30</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Wedding cake<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/cea5a113adad4656959b69ee3d5fbc2d.svg"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--black);\">22:00</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Party time!<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/4245602635b04eb1af32c41fbe7bb1e8.svg"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-6lswp2 pb--80" name={"obrazek"} style={{"paddingTop":498,"marginBottom":0}} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/5f7a28c09b2d499d89dc5cacaacaaffd_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/5f7a28c09b2d499d89dc5cacaacaaffd_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/5f7a28c09b2d499d89dc5cacaacaaffd_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/5f7a28c09b2d499d89dc5cacaacaaffd_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/5f7a28c09b2d499d89dc5cacaacaaffd_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/5f7a28c09b2d499d89dc5cacaacaaffd_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/5f7a28c09b2d499d89dc5cacaacaaffd_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32588/5f7a28c09b2d499d89dc5cacaacaaffd_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Column className="pb--50 pt--80" name={"polozky"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"paddingTop":0}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":880}} content={"<span style=\"color: var(--black);\">Dress-code</span><br>"}>
              </Title>

              <Image alt={""} src={"https://cdn.swbpg.com/t/32588/aba425965f154a2fb6e8074441836513_e=291x314x929x619_s=660x_.png"} sizes={"100vw"} style={{"maxWidth":360,"marginTop":0}} srcSet={"https://cdn.swbpg.com/t/32588/aba425965f154a2fb6e8074441836513_e=291x314x929x619_s=350x_.png 350w, https://cdn.swbpg.com/t/32588/aba425965f154a2fb6e8074441836513_e=291x314x929x619_s=660x_.png 660w, https://cdn.swbpg.com/t/32588/aba425965f154a2fb6e8074441836513_e=291x314x929x619_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box lh--2" style={{"maxWidth":880,"marginTop":0}} content={"<span style=\"color: var(--black); font-style: italic;\">Дорогі пані, м</span><span style=\"color: var(--black); font-style: italic;\">и намагалися зробити нашу урочистість красивою, тому будемо дуже вдячні, якщо в день весілля у вас вийде дотримуватися палітри кольорів.<br></span><span style=\"color: var(--black); font-style: italic;\">Для чоловіків дрес код: чорний костюм і біла сорочка.<br></span><span style=\"color: var(--black); font-style: italic;\"><br><br></span><span style=\"font-style: italic;\"><span style=\"color: var(--color-dominant);\">Підтримайте, будь ласка, нашу ідею:\n<br>Не даруйте нам квіти, нехай ці кошти підуть на користь, адже ми організували&nbsp;<a href=\"https://send.monobank.ua/jar/8zZqk32F1L\">скриньку для ЗСУ </a>і відправимо всі зібрані кошти, щоб наблизити нашу перемогу!</span><span style=\"color: var(--black);\"><br></span></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"program"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 pt--12" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43" style={{"maxWidth":1080}} content={"<span style=\"color: var(--black);\">Будь ласка, підтвердьте вашу присутність!</span><br>"}>
              </Title>

              <Text className="text-box lh--2 mt--25" style={{"maxWidth":880}} content={"<span style=\"font-style: italic;\">Переконливе прохання для кожного гостя вказати о</span>бмеження&nbsp;<span style=\"font-style: italic;\"><br>&nbsp;у виборі страви (алергії, веганство, вегетаринство та інші).\n<br>Так персонал зможе подбати про вас якнайкраще!</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"program-2"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pb--20">
              
              <ContactForm className="--shape2 fs--22" style={{"maxWidth":1050}} action={"/contact"} fields={[{"name":"Ім'я та прізвище","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Номер телефону","type":"text","placeholder":""},{"name":"Обмеження у виборі страви","type":"text","placeholder":""},{"name":"Відправити","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogalerie"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32588/2a841f248bfe4a4e9206cc16b80fe491_e=326x769x1307x1306_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32588/2a841f248bfe4a4e9206cc16b80fe491_e=326x769x1307x1306_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32588/2a841f248bfe4a4e9206cc16b80fe491_e=326x769x1307x1306_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32588/2a841f248bfe4a4e9206cc16b80fe491_e=326x769x1307x1306_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32588/5e2b02f9b1df4b4b96fb32dea0a5c64d_e=271x686x1307x1306_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32588/5e2b02f9b1df4b4b96fb32dea0a5c64d_e=271x686x1307x1306_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32588/5e2b02f9b1df4b4b96fb32dea0a5c64d_e=271x686x1307x1306_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32588/5e2b02f9b1df4b4b96fb32dea0a5c64d_e=271x686x1307x1306_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32588/2bfcdb922bc44f7eb930c8f472af72f5_e=326x840x1307x1306_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32588/2bfcdb922bc44f7eb930c8f472af72f5_e=326x840x1307x1306_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32588/2bfcdb922bc44f7eb930c8f472af72f5_e=326x840x1307x1306_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32588/2bfcdb922bc44f7eb930c8f472af72f5_e=326x840x1307x1306_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"5bobwx6v5at"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43" content={"<span style=\"color: var(--black);\">З усіх питань ви можете звернутися до нашого весільного організатора:</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: var(--black);\">Elizaveta Volkhina\n<br>+ 420 775 314 669\n<br>Info@brilliant-wedding.cz</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80" name={"paticka"} style={{"paddingTop":0,"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" style={{"maxWidth":""}} content={"<span style=\"color: var(--black);\">Будемо на вас чекати!</span><br>"}>
              </Title>

              <Text className="text-box text-box--center lh--2 mt--12" style={{"maxWidth":880}} content={"<span style=\"font-style: italic;\">З любов'ю,\n<br>Ксенія та Дмитро!</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}